import React, {useState, useEffect} from "react"
import { Link, graphql } from "gatsby";

import SharingButtons from './ShareBtns'
// import Img from "gatsby-image";

import PrimaryLayout from "./PrimaryLayout"
import SEO from './SEO';

const BlogSingle = ({data}) => {
  const allPost = data.allWordpressPost.nodes;
  const post = data.wordpressPost;
  const [back, setBack] = useState(false);

  useEffect(() => {
    if (back) {
      if (typeof window !== 'undefined') {
        window.history.back()
      }
    }
  }, [back, setBack]);

  let index = allPost.findIndex( x => x.title === post.title );
  // console.log(allPost[index + 1].slug );

  return (
    <PrimaryLayout>
      <SEO 
        title={post.title}
        description={post.excerpt}
        image={post.featured_media && post.featured_media.source_url}
      />
      <section id="primary" className="content-area">
        <main id="main" className="site-main">
          <article
            id="post-947"
            className="post-947 post type-post status-publish format-standard has-post-thumbnail hentry category-business category-marketing category-sales category-technology tag-artificial-intelligence tag-customer tag-customer-engagement tag-customer-loyalty tag-customer-service tag-cx entry"
          >
            <div className="article-top-banner">
              <div
                className="post-header-image"
                aria-label="How AI Is Transforming Customer Service And Customer Experience?"
                alt="AI"
              >
                  <img
                    className="main-desktop-image lazyload"
                    data-src={post.featured_media && post.featured_media.source_url}
                    alt="AI"
                  />
                {/* <Img 
                  fixed={post.featured_media.localFile.childImageSharp.fixed}
                  alt={post.title}
                  /> */}
              </div>
            </div>

            <div className="container-fluid ">
              <div className="container-fluid primary-color-bg ">
                <div className="row p-0 m-0">
                  <div className="container main-container-padding-t-5 main-container-padding-b-2 px-0 mob-main-container-padding-tb-2">
                    <div className="row">
                      <div className="col-md-12 px-0 px-md-3">
                        <div className="single-post-content-area blog-single-post">
                          <SharingButtons className="social-Haligned-title" title={post.title} url={`https://alkye.com/${post.slug}`} />
                          <div
                            className="row aos-init"
                          >
                            <div className="col-md-12 text-left georgia-text pt-0 pb-2">
                              <div className="back-post justify-content-between d-none align-items-center mb-md-3 mb-2">
                                <button className="btn p-0" onClick={() => setBack(true)}><i className="la la-angle-left"></i> Back</button>
                                {allPost[index + 1] ? <Link className="btn text-dark p-0" to={`/${allPost[index + 1] ? allPost[index + 1].slug : null}`}>Next <i className="la la-angle-right"></i></Link> : null}
                              </div>

                              <h1 style={{marginTop: '0px'}} dangerouslySetInnerHTML={{__html: post.title}} />
                            </div>
                          </div>

                          <div
                            className="row aos-init"
                          >
                            <div className="col-md-12 text-left georgia-text pb-2">
                              <p className="card-title secondary-font-light m-0 reduced-letter-spacing">
                                {post.categories[0].name} |{" "}
                                <small className="text-muted primary-font captalise georgia-text">
                                  <time dateTime="2020-07-19T10:22:21+00:00">
                                    {post.date}
                                  </time>
                                </small>
                              </p>
                            </div>
                          </div>

                          <div
                            className="row aos-init main-container-padding-t-5"
                          >
                            <div className="col-md-12">
                              <div className="entry-content" dangerouslySetInnerHTML={{__html: post.content}}></div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <ul className="post-tag-on-post">
                                {post.tags ? post.tags.map((tag, index) => (
                                  <li key={index}>
                                    <Link
                                      to="/tags/"
                                      className="post-tag-link"
                                      state={{ tagPath: tag.slug, tagName: tag.name, tagDescription: tag.description }}
                                    >
                                      &nbsp;&nbsp;&nbsp;#{tag.name}
                                    </Link>
                                  </li>
                                )): null}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex px-3 py-3">
                          {/* {post.slug} */}
                          <SharingButtons title={post.title} url={`https://alkye.com/${post.slug}`} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>
      </section>
    </PrimaryLayout>
  )
}

export default BlogSingle;


export const query = graphql `
    query($slug: String!){
        wordpressPost(slug: {eq: $slug}) {
          content
          excerpt
          title
          slug
          date(formatString: "MMMM D, Y")
          featured_media {
            source_url
          }
          categories {
            name
          }
          tags {
            name
            slug
            description
          }
        }
        allWordpressPost {
          nodes {
            title
            slug
          }
        }
    }
`