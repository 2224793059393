import React from 'react';
import { Facebook, Twitter, Email, LinkedIn } from 'react-sharingbuttons';

import 'react-sharingbuttons/dist/main.css';

const SharingButtons = (props) => {
    const url = props.url
    const shareText = 'Check this site!'
  
    return (
      <div className={`social-valigned ${props.className}`}>
        <Facebook url={url} />
        <Twitter url={url} shareText={shareText} />
        <a className="react-sharing-button__link" target="_blank" rel="noreferrer" href={'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + shareText + '&summary=' + shareText + '&source=' + url}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M6.5 21.5h-5v-13h5v13zM4 6.5h-.04c-1.5 0-2.5-1.18-2.5-2.48 0-1.33 1.02-2.4 2.56-2.4s2.5 1.1 2.52 2.43c0 1.3-.98 2.45-2.55 2.45zm11.5 6c-1.1 0-2 .9-2 2v7h-5s.06-12 0-13h5V10s1.55-1.46 3.94-1.46c2.96 0 5.06 2.15 5.06 6.3v6.66h-5v-7c0-1.1-.9-2-2-2z" />
        </svg>
        </a>
        <Email url={url} subject={props.title} />
      </div>
    )
}

export default SharingButtons;